/**
 * The framework library to develop Enterprise Level Business Applications
 *  
 * @remarks
 * 
 * aeppic (pronounced /appic/ as the ae really comes from the German ä) is 
 * a <b>business application platform</b> with a strong structure of ensuring
 * applications can be developed quickly and then last for multiple decades.
 * 
 * How this is done from an architectural point as well as from an
 * implementation side is better explained in the <a href="../../..">Overview</a>
 * instead of reading this reference documentation.
 * 
 * @packageDocumentation
 * 
 * @privateRemarks
 * 
 * The Overview href links back to the main documentation entry point which
 * will always be two levels up (from <root>/code/aeppic/aeppic.html -> <root>/)
 * 
 */

import * as Types from '@aeppic/types'
export { Types }

export { default as uuid } from '@aeppic/shared/uuid'
export { EventEmitter } from '@aeppic/shared/event-emitter'

export * from './aeppic/index.js'
export { default as default } from './aeppic/index.js'

export {  ChangeType } from './model/change-tracker.js'
export type { Change, ChangeSet } from './model/change-tracker.js'

export { default as Preferences } from './aeppic/utils/preferences.js'
export { default as Translator } from './aeppic/utils/translator.js'
export { default as Model, importIntoModel, Importer, EditableDocument, Form, Indexing, getDocumentId, toQueryAndOptions, Feature as ModelFeature, sort, enableProxyMode, disableProxyMode } from './model/index.js'
export type { ImportResult,  FindOptions, SaveOptions, NewDocumentOptions, FilterFunction, LimitedAccessOptions, StampOptions, UndeleteOptions, GetOptions, ChangeDocumentFormOptions, DescendantTimestampTracking } from './model/index.js'
export type { Logger } from './model/log.js'
export type { DocumentFilter } from './model/filter.js'
export { Features } from './aeppic/features.js'
export type { FeatureNamespace } from './aeppic/features.js'
export { Warn } from './aeppic/warn.js'
export { LookupTrace } from './aeppic/lookup/form-oriented-lookup.js'
export { DesignLookup, DesignMatchStrategy } from './aeppic/lookup/design-lookup.js'
export { ControlLookup, ControlMatchStrategy } from './aeppic/lookup/control-lookup.js'

import * as Querying from './model/query/index.js'
export { Querying }

export { isDocument, isReference } from './model/is.js'
export { resolveDocumentPath } from './model/resolve-path.js'
export { default as resolveField } from './model/query/resolve-field.js'

export { Commands } from './aeppic/commands/index.js'
export type { ExecuteOptions as CommandExecuteOptions } from './aeppic/commands/index.js'

// Re-Export modules incorporated into build to ensure users can use the exact same versions.
// Modules are exported in the export 'modules' and are named exactly like their package names.
import * as luxon from 'luxon'
import Vue from './externals/vue.js' 
import animejs from 'animejs'
import FileSaver from 'file-saver'

import Popup from './aeppic/popup.js'

const modules = {
  luxon,
  vue: Vue,
  Vue,
  animejs,
  'file-saver': FileSaver,
  Popup,
}

export { modules }
