import { once } from '../warn.js'

const loadedStyles = new Map()

declare const less

export function unloadStyle(id) {
  if (typeof document === 'undefined') {
    return
  }
  
  removeStyleFromHead(id)
}

export type Group = 'ae-root' | 'ae-dynamic' | string

const CSS_GROUP_ORDER = [ 'ae-root', 'ae-dynamic' ]

export async function compileStyle(css: string, type?: string) {
  if (css.trim().length === 0) {
    return ''
  }

  if (type === 'less') {
    if (typeof less === 'undefined' || !less.render) {
      once('css', 'less-css:required', 'WARN: less-css is required to compile style.', css.substring(0, 10) + '...')
      return ''; // * less-css is required */
    }

    try {
      const result = await less.render(css)
      return result.css
    }
    catch (err) {
      console.error('ERROR: less-css compilation failed.', err)
    }
  }

  return css
}

export function loadStyle(css: string, id?: string, group: Group = 'ae-dynamic', sortIndex: number = null) {
  return _loadStyle(id, group, sortIndex, { css })
}

export function loadStyleFromUrl(cssUrl: string, id?: string, group: Group = 'ae-dynamic', sortIndex: number = null) {
  return _loadStyle(id, group, sortIndex, { cssUrl })
}

function _loadStyle(id: string, group: Group, sortIndex: number, content: { css?: string, cssUrl?: string }) {
  if (typeof document === 'undefined') {
    return
  }

  const designatedId = id || content.cssUrl || content.css

  const loadedStyle = loadedStyles.get(designatedId)

  if (loadedStyle && !id) {
    return
  }

  const style = addStyleToHead(id, group, sortIndex, content)

  if (style) {
    loadedStyles.set(designatedId, content)
  }
}

function addStyleToHead(id: string, group: Group, sortIndex: number, content: { css?: string, cssUrl?: string }) {
  if (typeof document === 'undefined') {
    return
  }
  
  const style = buildStyle(id, group, sortIndex, content)
  insertIntoHead(id, group, sortIndex, style)
  return style
}

function buildStyle(id: string,  group: Group, sortIndex: number, content: { css?: string, cssUrl?: string }) {
  const style: HTMLStyleElement|HTMLLinkElement =   content.css ? document.createElement('style')
                                                  : content.cssUrl ? document.createElement('link')
                                                  : null

  if (id) {
    style.dataset.aeCssId = id
    style.dataset.aeCssGroup = group

    if (sortIndex != null) { 
      style.dataset.aeCssSort = sortIndex.toString()
    }
  }

  if (content.css) {
    style.appendChild(document.createTextNode(content.css))
  } else if (content.cssUrl) {
    style.setAttribute('rel', 'stylesheet')
    style.setAttribute('href', content.cssUrl)
  }

  return style
}

function insertIntoHead(id: string, group: string, sortIndex: number, style: HTMLStyleElement) {
  const existingStyle = findStyle(id)

  if (existingStyle) {
    const existingGroup = existingStyle.dataset.aeCssGroup

    if (existingGroup === group) {
      insertAfter(style, existingStyle)
      existingStyle.remove()
      return
    } 
  } 

  const rootStyles = <NodeListOf<HTMLStyleElement>> findStylesOfGroup('ae-root')
  const dynamicStyles = <NodeListOf<HTMLStyleElement>> findStylesOfGroup('ae-dynamic')
  
  if (rootStyles.length === 0 && dynamicStyles.length === 0) {
    document.head.appendChild(style)
  } else {
    if (group === 'ae-root') {
      if (rootStyles.length > 0) {
        insertIntoGroup(rootStyles, id, sortIndex, style)
      } else if (dynamicStyles.length > 0) {
        document.head.insertBefore(style, dynamicStyles.item(0))
      } else {
        document.head.appendChild(style)
      }
    } else if (group === 'ae-dynamic') {
      if (dynamicStyles.length > 0) {
        insertIntoGroup(dynamicStyles, id, sortIndex, style)
      } else {
        insertAfter(style, rootStyles.item(rootStyles.length - 1))
      }
    } else {
      const groupStyles = <NodeListOf<HTMLStyleElement>> findStylesOfGroup(group)

      if (groupStyles.length > 0) {
        insertIntoGroup(groupStyles, id, sortIndex, style)
      } else {
        document.head.appendChild(style)
      }
    }
  }
}

function insertIntoGroup(styles: NodeListOf<HTMLStyleElement>, id: string, sortIndex: number, newStyle: HTMLStyleElement) {
  const styleThatShouldFollow = findStyleThatShouldFollow(styles, id, sortIndex, newStyle)

  if (styleThatShouldFollow) {
    document.head.insertBefore(newStyle, styleThatShouldFollow)      
  } else {
    insertAfter(newStyle, styles.item(styles.length - 1))
  }
}

function findStyleThatShouldFollow(styles: NodeListOf<HTMLStyleElement>, id: string, sortIndex: number, newStyle) {
  for (let i = 0, l = styles.length; i < l; i += 1) {
    const style = styles.item(i)

    const styleShouldFollow = shouldStyleFollow(style, id, sortIndex)

    if (styleShouldFollow) {
      return style
    }
  }

  return null
}

/*
 * Styles always have an data-ae-css-id and CAN have a data-ae-css-sort
 * 
 * Styles WITH sort are sorted by that number
 * Styles WITH sort are before those WITHOUT
 * Styles WITHOUT sort are sorted by id
 */
function shouldStyleFollow(style: HTMLStyleElement, id: string, sortIndex: number) {
  if (sortIndex == null) {
    if (style.dataset.aeCssSort == null) {
      return style.dataset.aeCssId > id
    } else {
      return false
    }
  } else {
    if (style.dataset.aeCssSort == null) {
      return true
    } else {
      return parseInt(style.dataset.aeCssSort, 10) > sortIndex
    }
  }
}

function insertAfter(newElement: Element, targetElement: Element) {
  // target is what you want it to go after. Look for this elements parent.
  let parent = targetElement.parentNode

  // if the parents lastChild is the targetElement...
  if (parent.lastChild === targetElement) {
    // add the newElement after the target element.
    parent.appendChild(newElement)
  } else {
    // else the target has siblings, insert the new element between the target and it's next sibling.
    parent.insertBefore(newElement, targetElement.nextSibling)
  }
}

function findStyle(id): HTMLStyleElement {
  return document.querySelector(`style[data-ae-css-id="${id}"]`)
}

function findStylesOfGroup(group: Group) {
  return document.querySelectorAll(`style[data-ae-css-group="${group}"]`)
}

function removeStyleFromHead(id) {
  if (typeof document === 'undefined') {
    return
  }

  const previousVersion = findStyle(id)
  
  if (previousVersion) {
    previousVersion.remove()
  }

  loadedStyles.delete(id)  
}


export async function loadStyles(fetchTarget, fileNames, fetchFiles: (fetchTarget, fileNames) => Promise<string[]>) {
  if (!fileNames) {
    return
  }

  const styles = await fetchFiles(fetchTarget, fileNames)

  for (const style of styles) {
    loadStyle(style)
  }
}

export function unloadStylesInGroup(groupId: string) {
  const styles = <NodeListOf<HTMLStyleElement>> findStylesOfGroup(groupId)
  
  for (let i = 0; i < styles.length; i ++) {
    const style = styles.item(i)
    style.remove()
  }
}
