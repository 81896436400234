import { Logger, SimpleConsoleLogger } from '../model/log.js';

let WARNED_ABOUT: Map<string, Set<string>> = null

export class Warn {
  private _log: Logger

  constructor(log: Logger = SimpleConsoleLogger) {
    this._log = log.child({ class: 'Warn' })
  }

  once(namespace: string, key: string, ...args: any[]) {
    let log = this._log.warn.bind(this._log)
    onceTo(namespace, key, log, ...args)
  }
}

export function once(namespace: string, key: string, ...args: any[]) {
    onceTo(namespace, key, console.warn.bind(console), ...args)
}

export function onceTo(namespace: string, key: string, outputFn, ...args: any[]) {
    if (!WARNED_ABOUT) {
        WARNED_ABOUT = new Map()
    }
    
    let set: Set<string> = WARNED_ABOUT.get(namespace)
    
    if (!set) {
        set = new Set()
        WARNED_ABOUT.set(namespace, set)
    }
    
    if (!set.has(key)) {
        set.add(key)
        outputFn(...args)
    }
}
