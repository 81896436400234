import Types from '@aeppic/types'
import { DateTime, Duration as DateTimeDuration, Interval as DateTimeInterval } from 'luxon'

import { EditableDocument } from '../../model' 
import { RaiseUnsupportedFunction, runActions } from '../../dynamic/run-action.js'

import { AeppicInterface, AeppicUiInterface, Write } from '../aeppic'
import { WriteLockedAeppic } from '../write-locked-aeppic'
import { InMemoryLog, Logger, buildLogger } from '../../model/log'

import { runCode } from '../../dynamic/dynamic-code.js'

export interface ActionOptions {
  isolation: 'best' | 'vm' | 'none',
  index?: number
  Logger?: Logger
}

export interface ActionResult {
  ok: boolean
  error?: string
  value?: any
  log?: object[]
  writes?: Write[]
}

export interface ExecuteOptions {
  checkMode?: boolean
  noIsolation?: boolean
  logLimit?: number
  verbose?: boolean
  navigation?: boolean
}

export interface CommandActionContext {
  document: EditableDocument
  target: EditableDocument
  value: EditableDocument|any
  params: object
}

export const DEFAULT_ACTION_OPTIONS: ActionOptions = {
  isolation: 'best',
  index: -1,
}

export type CommandAeppicInterface = Omit<AeppicInterface, 'Commands'|'Actions'> 

const COMMAND_LOG_SIZE = 1000

export class CommandExecutor {
  private _aeppic: CommandAeppicInterface

  constructor({ Aeppic }: { Aeppic: CommandAeppicInterface }) {
    this._aeppic = Aeppic
    Object.freeze(this)
  }

  async execute(command: Types.Document, target: Types.IdOrReference|EditableDocument, commandParameters?: any, options: ExecuteOptions = {}) {
    const inMemoryLog = new InMemoryLog({ size: COMMAND_LOG_SIZE, defaults: { scope: 'Commands', source: 'command', commandId: command.id }})
    const commandLog = inMemoryLog.child()

    const targetDocument = await this._getEditable(target)

    if (!targetDocument) {
      commandLog.error({ type: 'target:lookup', target })
      throw new Error(`Unknown target or target not accessible (${JSON.stringify(target)})`)
    }

    const log = commandLog.child({ targetId: targetDocument.id })
    log.debug({ type: 'command:execute:begin' }, 'Executing command %s', command.id)

    const commandWantsToCreateDocument = isActiveReference(command.data.form) || isActiveReference(command.data.templateDocument)
    const commandReferencesDocument = isActiveReference(command.data.document)

    let ok = true
    let actionResults: ActionResult[] = null
    let document: EditableDocument
    let otherDocuments: EditableDocument[]
    let value
    let error

    if (commandWantsToCreateDocument) {
      const parentDocumentId = await this._getParentForNewDocument(command, targetDocument)
      const createdDocuments = await this._createDocument(command, parentDocumentId)

      document = createdDocuments.document
      otherDocuments = createdDocuments.otherDocuments

      log.debug({ type: 'document:new', id: document.id, f: document.f, p: document.p, cloneOf: document.cloneOf })
    } else if (commandReferencesDocument) {
      document = await this._getEditable(<Types.Reference>command.data.document)
    } else {
      document = targetDocument
    }

    const writes: Write[]  = [] 
    const actions = await this._getActions(command)

    if (actions && actions.length > 0) {
      const context: CommandActionContext = {
        document,
        target: targetDocument,
        value: document,
        params: commandParameters || {},
      }
      
      log.debug({ type: 'actions:run:start', context })

      const writeLockedAeppic = new WriteLockedAeppic(this._aeppic, { writes, Logger: log }) as CommandAeppicInterface
  
      const actionLog = log.child({ command: this._aeppic.asReference(command), target: this._aeppic.asReference(targetDocument) })

      const globals = {
        context,
        params: context.params,
        Aeppic: writeLockedAeppic,
        log: actionLog.child({ type: 'actions:run:execute' }),
      }

      actionResults = await runActions(actions, globals, { isolation: options.noIsolation ? 'none' : 'best', Logger: actionLog } )

      if (actionResults) {
        if (!options.checkMode) {
          log.debug({ type: 'actions:writes:apply:start' })
          this._aeppic.applyWrites(writes)
          log.debug({ type: 'actions:writes:apply:end' })
        } else if (actionResults) {
          log.info({ type: 'actions:writes:skip' }, 'Not applying writes due to checkMode')
        }
      }

      const numberOfExecutedActions = actionResults.length
      const lastResult = actionResults[actionResults.length - 1]
      
      log.debug({ type: 'actions:run:end', numberOfExecutedActions, ok: lastResult ? lastResult.ok : null}, 'Actions ran' )

      if (lastResult) {
        if (lastResult.ok) {
          value = lastResult.value
        } else {
          ok = ok && false
          value = null
          error = lastResult.error
        }
      } else {
        log.debug({ type: 'actions:run:end' }, 'No result')
      }

      if (!ok) {
        log.error({ type: 'actions:failed', action: actions[numberOfExecutedActions - 1], error: lastResult.error })
      }
    }

    let returnDocument: Types.Document | EditableDocument = null

    if (value === undefined) {
      value = document
    }
    
    returnDocument = document
   
    if (ok) {
      if (command.data.save) {
        if (!options.checkMode) {
          if (EditableDocument.isEditableDocument(value)) {
            log.debug({ type: 'document:save', id: value.id, f: value.f, p: value.p, cloneOf: value.cloneOf })
            this._aeppic.save(value)

            if (otherDocuments && otherDocuments.length) {
              log.debug({ type: 'otherDocuments:save', otherDocuments: otherDocuments.map(o => ({ id: o.id, f: o.f, p: o.p, cloneOf: o.cloneOf})) })
              this._aeppic.saveAll(otherDocuments)
            }
          }
        } else {
          log.debug({ type: 'document:save:skip', id: value.id, f: value.f, p: value.p, cloneOf: value.cloneOf })

          if (otherDocuments && otherDocuments.length) {
            log.debug({ type: 'otherDocuments:save:skip', otherDocuments: otherDocuments.map(o => ({ id: o.id, f: o.f, p: o.p, cloneOf: o.cloneOf})) })
          }
        }
      } else if (otherDocuments && otherDocuments.length) {
        throw new Error(`Save is required because the cloned Template has children`)
      }
    }

    const commandResult = {
      command,
      log: Array.from(inMemoryLog.enumerate(options.logLimit || 100)),
      ok,
      document: returnDocument, // TODO: Might need to be removed (or document.cloneAsDocument())
      value,
      error,
      writes: writes.map(cleanWrite),
      actionResults
    }

    if (ok) {
      log.info({ type: 'command:execute:end', result: commandResult })
    } else {
      log.error({ type: 'command:execute:error', error })
    }

    if (options.verbose) {
      return commandResult
    }

    const minimalResult = { ok: commandResult.ok, value: commandResult.value, error: commandResult.error }

    const commandDidChangeDocument = commandWantsToCreateDocument || document.hasRevisions

    if (commandDidChangeDocument) {
      return { ...minimalResult, document: commandResult.document }
    }

    return minimalResult
  }

  private async _getActions(command: Types.Document) {
    if (command.data.actions && (<Types.Reference[]>command.data.actions).length > 0) {
      return this._aeppic.getAll(<Types.Reference[]>command.data.actions)
    } else if (command.data.action && (<Types.Reference>command.data.action).id) {
      return [await this._aeppic.get(<Types.Reference>command.data.action)]
    } else {
      return null
    }
  }

  private async _getParentForNewDocument(command: Types.Document, targetDocument: EditableDocument) {
    if (command.data.document && (<Types.Reference>command.data.document).id) {
      return (<Types.Reference>command.data.document).id
    } else {
      return targetDocument.id
    }
  }

  private async _createDocument(command: Types.Document, parentDocumentId: string) {
    if (command.data.form && (<Types.Reference>command.data.form).id) {
      return {
        document: await this._aeppic.new((<Types.Reference>command.data.form).id, parentDocumentId)
      }
    } else if (command.data.templateDocument && (<Types.Reference>command.data.templateDocument).id) {
      if (command.data.cloneTemplateDocumentOnly) {
        return {
          document: await this._aeppic.clone((<Types.Reference>command.data.templateDocument).id, parentDocumentId)
        }
      }

      const cloneResult = await this._aeppic.cloneDeep((<Types.Reference>command.data.templateDocument).id, parentDocumentId)

      return {
        document: cloneResult.root,
        otherDocuments: cloneResult.documents.slice(1)
      }
    } else {
      throw new Error('Missing information in command')
    }
  }

  private  async _getEditable(doc: Types.IdOrReference | EditableDocument) {
    if (EditableDocument.isEditableDocument(doc)) {
      return doc
    } else {
      return this._aeppic.edit(doc)
    }
  }
}

function cleanWrite(change: Write) {
  return {
    type: change.type,
    arguments: change.arguments.map(cleanWriteParameter)
  }
}

function cleanWriteParameter(variable: any|EditableDocument): Types.Document {
  if (EditableDocument.isEditableDocument(variable)) {
    return variable.cloneAsDocument()
  } else {
    return variable
  }
}

function isActiveReference(ref: any) {
  if (ref && ref.id) {
    return true 
  }

  return false
}
